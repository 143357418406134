<template>
  <div id="muorder">
    <top :name="text" />
    <p class="block" @click="rengong">进入南航人工选座</p>
    <p class="block" @click="muclick">进入东航行李额下单</p>
    <div class="two">
      <input v-model="value" type="text" placeholder="姓名/票号" />
      <p @click="sousuo" class="block">搜索</p>
      <p @click="all" class="block">显示全部数据</p>
    </div>
    <!-- 数据报表 -->
    <template>
      <download-excel
        class="export-excel-wrapper"
        :data="res"
        :fields="json_fields"
        header="东航行李额收益报表"
        name="东航行李额收益报表.xls"
      >
        <el-button type="success">导出</el-button>
      </download-excel>
    </template>
    <!-- 订单数据表格 全部数据 -->
    <div style="overflow: auto; height: 75vh" v-show="all_show">
      <table border="1" style="font-size: 0.3rem; overflow: auto">
        <tr>
          <td>姓名</td>
          <td>票号</td>
          <td>时间</td>
          <td>额度</td>
          <td>实际里程</td>
          <td>原始里程</td>
          <td>卡号</td>
          <td>卡密</td>
          <td>支付密码</td>
        </tr>
        <tr v-for="(item, index) in res" :key="index">
          <td>{{ item.passenger_name }}</td>
          <td>{{ item.ticket_no }}</td>
          <td>{{ item.create_time }}</td>
          <td>{{ item.baggage_allowance }}</td>
          <td>{{ item.actual_mileage }}</td>
          <td>{{ item.mileage }}</td>
          <td>{{ item.account[0] }}</td>
          <td>{{ item.account[1] }}</td>
          <td>{{ item.account[2] }}</td>
        </tr>
      </table>
    </div>
    <!-- 搜索数据 -->
    <div style="overflow: auto; height: 75vh" v-show="sousuo_show">
      <table border="1" style="font-size: 0.3rem; overflow: auto">
        <tr>
          <td>姓名</td>
          <td>票号</td>
          <td>时间</td>
          <td>额度</td>
          <td>实际里程</td>
          <td>原始里程</td>
          <td>卡号</td>
          <td>卡密</td>
          <td>支付密码</td>
        </tr>
        <tr v-for="(item, index) in sousuo_res" :key="index">
          <td>{{ item.passenger_name }}</td>
          <td>{{ item.ticket_no }}</td>
          <td>{{ item.create_time }}</td>
          <td>{{ item.baggage_allowance }}</td>
          <td>{{ item.actual_mileage }}</td>
          <td>{{ item.mileage }}</td>
          <td>{{ item.account[0] }}</td>
          <td>{{ item.account[1] }}</td>
          <td>{{ item.account[2] }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
  
  <script>
import top from "../components/filltop.vue";
export default {
  components: {
    top,
  },
  data() {
    return {
      text: "东航行李额订单",
      data: [],
      res: [],
      value: "",
      all_show: true,
      sousuo_show: false,
      sousuo_res: [],
      json_fields: {
        姓名: "passenger_name",
        票号: "ticket_no",
        时间: "create_time",
        额度: "baggage_allowance",
        原本支出里程: "actual_mileage",
        实际支出里程: "mileage",
        东航卡信息: "account_information",
      },
      DetailsForm: [
        {
          passenger_name: "",
          ticket_no: "",
          create_time: "",
          baggage_allowance: "",
          actual_mileage: "",
          mileage: "",
          account_information: "",
        },
      ],
    };
  },
  mounted() {
    this.axios({
      url: "https://wx.yaolvxing.com/luggage_allowance_inside/orderlist",
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then((res) => {
        res.data.data.forEach((item, index) => {
          res.data.data[index].account =
            item.account_information.split(" ");
        });
        this.res = res.data.data;
        console.log(this.res);
      })
      .catch((err) => {
        this.$toast.fail("接口报错");
      });
  },
  methods: {
    sousuo() {
      this.sousuo_res = [];
      for (let i in this.res) {
        if (
          this.res[i].ticket_no == this.value ||
          this.res[i].passenger_name == this.value
        ) {
          this.sousuo_res.push(this.res[i]);
          this.all_show = false;
          this.sousuo_show = true;
        }
      }
    },
    all(){
        this.all_show = true,
        this.sousuo_show = false
    },
    muclick() {
      this.$router.push({
        name: "mu",
      });
    },
    rengong() {
      this.$router.push({
        name: "inset",
      });
    },
  },
};
</script>
  
  
  
  <style scoped>
p {
  font-size: 0.3rem;
}
.xinxi {
  display: flex;
  justify-content: center;
}
.dis_san {
  width: 100vw;
  padding-top: 0.2rem;
  height: auto;
}
.dis_san div {
  width: 100vw;
  display: flex;
  justify-content: space-around;
}
.dis_san div p {
  margin-bottom: 0.1rem;
  display: block;
  text-align: center;
}
tr {
  text-align: center;
  padding: 0.2rem;
}
td {
  padding: 0.1rem;
}
.mian {
}
.fu {
  background: rgb(11, 78, 104);
  color: white;
}
.block {
  margin: 0.2rem;
  display: block;
  padding: 0.2rem;
  /* width: 20VW; */
  text-align: center;
  background: rgb(1, 42, 58);
  color: white;
}
.two {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
</style>